.aboutUs{
    width: 100%;
    overflow: hidden;
    margin-top: 50px;
    margin-bottom: 40px;
}
.aboutUs .aboutUs-main{
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    gap: 60px;
}
.aboutUs .aboutUs-main .aboutUs-side1{
    width: 500px;
    overflow: hidden;
    margin-left:auto;
}
.aboutUs .aboutUs-main .aboutUs-side1 .aboutUs-header h2{
    font-weight: bold;
}
.aboutUs .aboutUs-main .aboutUs-side1 .aboutUs-header h2 span{
    color: #FF5A5F;
}
.aboutUs .aboutUs-main .aboutUs-side1 .aboutUs-header p{
    color: gray;
    line-height: 1.3;
    font-size: 15px;
}
.aboutUs .aboutUs-main .aboutUs-side2{
    width: 500px;
    overflow: hidden;
}
.aboutUs .aboutUs-main .aboutUs-side2  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 900px) {
    .aboutUs{
        margin-top: 5px;
    }
    .aboutUs .aboutUs-main{
        flex-direction: column-reverse;
        gap: 40px;
    }
    .aboutUs .aboutUs-main .aboutUs-side1{
        width: 100%;
        margin-left:0;
    }
    .aboutUs .aboutUs-main .aboutUs-side2{
        width: 100%;        
        overflow: hidden;
    }

    
}