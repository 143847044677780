.product3{
    width: 100%;
    overflow: hidden;
}
.product3 .product3-main{
    display: flex;
    gap: 50px;
    justify-content:center;
    align-items:center;
}
.product3 .product3-main .product3-side2{
    width: 450px;
    overflow: hidden;   
}
.product3 .product3-main .product3-side2 img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.product3 .product3-main .product3-side1{
    width: 440px;
}
.product3 .product3-main .product3-side1 .product3-side1-header h2{
    font-weight: bold;
}
.product3 .product3-main .product3-side1 .product3-side1-header .product3-side1-para{
    color: grey;

}
.product3 .product3-main .product3-side1 .product3-btn button{
    border : none;
    outline: none;
    padding: 8px 12px;
    background-color: #FF5A5F;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
}
.product3 .product3-main .product3-side1 .product3-btn button:hover{
    border: 1px solid #FF5A5F !important;
    background-color: white !important;
    color: #FF5A5F !important; /* Ensure the text color contrasts on hover */
    transition:0.5s;
}

@media (max-width: 900px){

    .product3 .product3-main{
        flex-direction: column; 
    }
    .product3 .product3-main .product3-side2{
        width: 90%;
       
        overflow: hidden;   
    }
    .product3 .product3-main .product3-side1{
        width: 100%;
    }
    

}