.home{
    width: 100%;
    overflow: hidden;
}
.home .home-main{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 74px;
    margin-top: 91px;
}
.home .home-main .home-side1{
    width: 470px;
}
.home .home-main .home-side1 .home-side1-header h2{
    font-weight: bold;
    font-size: 36px;
}
.home .home-main .home-side1 .home-side1-header p{
    color: grey;
    line-height: 1.4;
    font-size: 16px;
}
.home .home-main .home-side1 .product-btn button{
    border : none;
    outline: none;
    padding: 8px 12px;
    background-color: #FF5A5F;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
}
.home .home-main .home-side1 .product-btn button:hover {
    border: 1px solid #FF5A5F !important;
    background-color: white !important;
    color: #FF5A5F !important; /* Ensure the text color contrasts on hover */
    transition:0.5s;
}

/* Side 2 */
.home .home-main .home-side2{
    width: 450px;
    overflow: hidden;
}
.home .home-main .home-side2 .home-img{
    width: 100%;
}
.home .home-main .home-side2 .home-img img{
    object-fit: cover;
    width: 100%;
}

@media (max-width:900px){
    .home .home-main{
        margin-top: 36px;
        flex-direction: column;
        gap: 34px;
    }
    .home .home-main .home-side1{
        width: 100%;
    }
    .home .home-main .home-side2{
        width: 100%;
    }
    .home .home-main .home-side1 .home-side1-header h2{
   
        font-size: 30px;
    }
    .home .home-main .home-side1 .home-side1-header p{
        font-size: 14px;
    }
}