.footer{
    width: 100%;
    background-color: #FF5A5F;
    padding-top: 86px;
    padding: 20px;
    overflow: hidden;
    font-family: 'Manrope';
}
.footer .footer-main {
    display: flex;
    align-items: center;
    gap: 60px;
}
.footer .footer-main .footer1 .footer1-header{
    width: 550px;
    color: white;
}
.footer .footer-main .footer1 .footer1-header h2{
    font-size: 54px;
}
.footer .footer-main .footer1 .footer1-header p{
    color: white;
}
.footer .footer-main .footer1 .footer-icons{
    display: flex;
    gap: 20px;
}
.footer .footer-main .footer2 .footer2-links{
    display: flex;
    flex-wrap: wrap;
    gap: 35px;
}
.footer .footer-main .footer2 .footer2-links a{
    color: white;
    text-decoration: none;
    font-size: 18px;
    cursor: pointer;
}
.footer hr{
    border: none;
    height: 2px;
    color: white; 
    background-color: white;
}
.footer .footer-rights{
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;
    color: white;
}

@media (max-width: 900px){
    .footer .footer-main {
        flex-direction: column;
        gap: 30px;
    }
    .footer .footer-main .footer1 .footer1-header{
        width: 100%;
    }
    .footer .footer-main .footer1 .footer1-header h2{
        font-size: 44px;
    }
}