.contact{
    width: 100%;
    overflow: hidden;
    margin-bottom: 100px;
}
.contact .contact-main{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 60px;
}
.contact .contact-main .contact-side1{
    width: 500px;
    overflow: hidden;
    margin-left:auto;
}
.contact .contact-main .contact-side1 .contact-header h2{
    font-weight: bold;
}
.contact .contact-main .contact-side1 .contact-header h2 span{
    color: #FF5A5F;
}
.contact .contact-main .contact-side1 .contact-header p{
    color: gray;
    line-height: 1.3;
    font-size: 15px;
}

.contact .contact-main .contact-side1 .contact-btn button{
    border : none;
    outline: none;
    padding: 8px 12px;
    background-color: #FF5A5F;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
}
.contact .contact-main .contact-side1 .contact-btn button:hover{
    border: 1px solid #FF5A5F !important;
    background-color: white !important;
    color: #FF5A5F !important; /* Ensure the text color contrasts on hover */
    transition:0.5s;
}
.contact .contact-main .contact-side2{
    width: 500px;
    overflow: hidden;
}
.contact .contact-main .contact-side2  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 900px) {
    .contact{
        margin-top: 5px;
    }
    .contact .contact-main{
        flex-direction: column-reverse;
        gap: 40px;
    }
    .contact .contact-main .contact-side1{
        width: 100%;
        margin-left:0;
    }
    .contact .contact-main .contact-side2{
        width: 100%;        
        overflow: hidden;
    }

    
}