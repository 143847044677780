.launch{
    width: 100%;
    overflow: hidden;
    margin-top:61px;
    position: relative;
    height: 300px;
}
.launch .launch-header h1{
    font-weight: bold;
}
.launch .launch-header span{
    color: #FF5A5F;
}
.launch .launch-main{
    display: flex;
}
.launch .launch-main .launch-side1{
    padding-top: 28px;
    padding-left: 30px;
}
.launch .launch-main .launch-side1 .launch-date{
    display: flex;
    gap: 50px;
}
.launch .launch-main .launch-side1 .launch-date .date-text{
    font-size: 78px;
    color: rgb(48, 48, 48);
    margin-bottom: 0px;
    line-height: 1.3;
}
.launch .launch-main .launch-side1 .launch-date .date-title{
    color: grey;
}

.launch .launch-main .launch-side2{
    margin-left:auto;
} 
.launch .launch-main .launch-side2 img{
    width: 130px;
    height: 130px;
    position: absolute;
    right: 10%;
    bottom: 0;
    animation: rocket 5s linear infinite;
}
@keyframes rocket{
    0%{
        bottom: 0;
        opacity: 0;
    }
    100%{
        bottom: 105%;
        opacity: 1;
    }
}
@media(max-width:900px){
    .launch .launch-main .launch-side1{
        padding-top: 18px;
        padding-left: 20px;
    }
    .launch .launch-main .launch-side1 .launch-date{
        display: flex;
        gap: 30px;
    }
    .launch .launch-main .launch-side1 .launch-date .date-text{
        font-size: 48px;
 
    }
}