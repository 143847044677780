.product{
   width: 100%;
   overflow: hidden;
   margin-top: 66px;
}
.product .product-header{
    text-align: center;
}
.product .product-header h1{
    font-weight: bold;
}
.product .product-header h1 span{
    color: #FF5A5F;
    font-weight: 800;

}
.product .products{
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
@media (max-width:900px){
    .product .products{
        padding-top: 10px;
    }
}