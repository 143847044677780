@import url('https://fonts.googleapis.com/css2?family=Fraunces:ital,opsz,wght@0,9..144,100..900;1,9..144,100..900&display=swap');

.navbar{
    background-color: #ffffff !important;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    font-family: 'Manrope';
    font-weight: 500;
}
.navbar .navbar-brand img{
    width: 65px !important;
    height: 65px !important;
}
.navbar .navbar-nav .nav-link{
    margin-left: 1em;
    display: inline-block;
    cursor: pointer;
}
.navbar .navbar-nav .nav-link .active{
    font-weight: bold;
}
@media (max-width:900px){
    .navbar .navbar-toggler {
        border: none !important;
        outline: none !important;
        box-shadow: none !important; /* To remove any shadow or focus effect */
    }
    
    .navbar .navbar-toggler:focus {
        border: none !important;
        outline: none !important;
        box-shadow: none !important; /* Also prevent focus outline */
    }
}