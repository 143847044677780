.product2{
    width: 100%;
    overflow: hidden;
}
.product2 .product2-main{
    display: flex;
    flex-direction: row-reverse;
    gap: 50px;
    justify-content:center;
    align-items:center;
}
.product2 .product2-main .product2-side2{
    width: 450px;
    overflow: hidden;   
}
.product2 .product2-main .product2-side2 img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.product2 .product2-main .product2-side1{
    width: 440px;
}
.product2 .product2-main .product2-side1 .product2-side1-header h2{
    font-weight: bold;
}

.product2 .product2-main .product2-side1 .product2-side1-header .product2-side1-para p{
    color: grey;

}


.product2 .product2-main .product2-side1 .product2-btn button{
    border : none;
    outline: none;
    padding: 8px 12px;
    background-color: #FF5A5F;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
}
.product2 .product2-main .product2-side1 .product2-btn button:hover{
    border: 1px solid #FF5A5F !important;
    background-color: white !important;
    color: #FF5A5F !important; /* Ensure the text color contrasts on hover */
    transition:0.5s;
}

@media (max-width: 900px){

    .product2 .product2-main{
        flex-direction: column; 
    }
    .product2 .product2-main .product2-side2{
        width: 90%;
       
        overflow: hidden;   
    }
    .product2 .product2-main .product2-side1{
        width: 100%;
    }
}